// Generated by Framer (e39ee10)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Image, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["m7B97IzlA", "elYRNbQ2w", "IydxUxoKM", "zLvcdopqv"];

const serializationHash = "framer-JETfZ"

const variantClassNames = {elYRNbQ2w: "framer-v-1b34cnh", IydxUxoKM: "framer-v-468bps", m7B97IzlA: "framer-v-11zu7qs", zLvcdopqv: "framer-v-89mr3d"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const toResponsiveImage = (value) => {
    if (typeof value === "object" && value !== null && typeof value.src === "string") {
        return value;
    };
    return typeof value === "string" ? {src: value} : undefined;
};


const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 250, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const humanReadableVariantMap = {"Close (Desktop)": "m7B97IzlA", "Close (Mobile)": "IydxUxoKM", "Open (Desktop)": "elYRNbQ2w", "Open (Mobile)": "zLvcdopqv"}

const getProps = ({height, id, image, width, ...props}) => { return {...props, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "m7B97IzlA", wrKxYtSkf: image ?? props.wrKxYtSkf} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap;image?: {src: string; srcSet?: string}; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, wrKxYtSkf, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "m7B97IzlA", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><Image {...restProps} {...gestureHandlers} background={{alt: "", fit: "fill", intrinsicHeight: 3000, intrinsicWidth: 4000, pixelHeight: 3000, pixelWidth: 4000, sizes: componentViewport?.width || "100vw", ...toResponsiveImage(wrKxYtSkf)}} className={cx(serializationHash, ...sharedStyleClassNames, "framer-11zu7qs", className, classNames)} data-border data-framer-name={"Close (Desktop)"} layoutDependency={layoutDependency} layoutId={"m7B97IzlA"} ref={ref ?? ref1} style={{"--border-bottom-width": "1px", "--border-color": "rgba(0, 0, 0, 0.1)", "--border-left-width": "1px", "--border-right-width": "1px", "--border-style": "solid", "--border-top-width": "1px", borderBottomLeftRadius: 14, borderBottomRightRadius: 14, borderTopLeftRadius: 14, borderTopRightRadius: 14, ...style}} {...addPropertyOverrides({elYRNbQ2w: {"data-framer-name": "Open (Desktop)"}, IydxUxoKM: {"data-framer-name": "Close (Mobile)"}, zLvcdopqv: {"data-framer-name": "Open (Mobile)"}}, baseVariant, gestureVariant)}><motion.div className={"framer-1o84imp"} data-framer-name={"Color"} layoutDependency={layoutDependency} layoutId={"RFbgxEgKB"} style={{backgroundColor: "var(--token-8766da81-4b16-4dc5-9abd-8dbd5bdab57d, rgb(26, 26, 26))", filter: "saturate(0)", opacity: 0, WebkitFilter: "saturate(0)"}}/></Image></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-JETfZ.framer-1r5qf3h, .framer-JETfZ .framer-1r5qf3h { display: block; }", ".framer-JETfZ.framer-11zu7qs { height: 360px; overflow: hidden; position: relative; width: 209px; will-change: var(--framer-will-change-override, transform); }", ".framer-JETfZ .framer-1o84imp { bottom: 0px; flex: none; left: 0px; mix-blend-mode: color; overflow: visible; position: absolute; right: 0px; top: 0px; }", ".framer-JETfZ.framer-v-1b34cnh.framer-11zu7qs { height: 460px; }", ".framer-JETfZ.framer-v-468bps.framer-11zu7qs { height: 220px; width: 300px; }", ".framer-JETfZ.framer-v-89mr3d.framer-11zu7qs { height: 260px; width: 343px; }", ".framer-JETfZ[data-border=\"true\"]::after, .framer-JETfZ [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 360
 * @framerIntrinsicWidth 209
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"elYRNbQ2w":{"layout":["fixed","fixed"]},"IydxUxoKM":{"layout":["fixed","fixed"]},"zLvcdopqv":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"wrKxYtSkf":"image"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerMgX07h8u6: React.ComponentType<Props> = withCSS(Component, css, "framer-JETfZ") as typeof Component;
export default FramerMgX07h8u6;

FramerMgX07h8u6.displayName = "Cards/Screenshot (image)";

FramerMgX07h8u6.defaultProps = {height: 360, width: 209};

addPropertyControls(FramerMgX07h8u6, {variant: {options: ["m7B97IzlA", "elYRNbQ2w", "IydxUxoKM", "zLvcdopqv"], optionTitles: ["Close (Desktop)", "Open (Desktop)", "Close (Mobile)", "Open (Mobile)"], title: "Variant", type: ControlType.Enum}, wrKxYtSkf: {title: "Image", type: ControlType.ResponsiveImage}} as any)

addFonts(FramerMgX07h8u6, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})